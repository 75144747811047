.myStakeContainer {
  padding: 20px 0px !important;
  border: 2px solid #0048ff;
  border-radius: 12px;
  min-height: 250px;
  /* min-height: max-content; */
  /* max-height: 500px; */
  max-height: 525px;
  overflow-y: scroll;
  display: grid;
  justify-content: center;
  align-items: center;
}

.StakeButton {
  outline: none;
  border: 1px solid #0048ff;
  background-color: transparent;
  color: white;
  padding: 8px 40px;
  border-radius: 12px;
  width: 160px;
}
.StakeButton:hover {
  background-color: #0048ff;
  color: white;
  border: 1px solid white;
}
