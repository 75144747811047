.livFinishedBtn {
  border-radius: 10px;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 10px 15px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid #0047ff;
  color: white;
  width: inherit;
}
.livFinishedBtn:hover {
  background-color: #0047ff;
  color: #fff;
}
.livFinishedBtn:focus {
  outline: none;
}
.mySelectBox {
  border: 1px solid #0047ff;
  border-radius: 10px;
  min-width: 142px;
}

.myNftSearchBox input {
  border-radius: 10px;
  border: 1px solid #0047ff;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: transparent !important;
  color: #fff;
  min-width: 142px;
}
.myNftSearchBox input:focus {
  outline: none;
  /* border: none; */
  border-radius: 10px;
  border: none;
}
.myNftSearchBox input:focus-visible {
  outline: none;
  border: none;
}

select option {
  background: transparent !important;
  background-color: transparent !important;
}
.single-staking-div {
  border-radius: 8px;
  box-shadow: rgba(221, 231, 231, 0.49) -1px -1px 3px 2px inset;
  padding-left: 14px;
}

.myStakingImg {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 1px !important;
  height: 160px !important;
  width: 100%;
  object-fit: fill;
}

div p {
  font-weight: 500;
}
.staking-text {
  font-size: 14px;
}

.fplSection div {
  background: transparent;
  border: 2px solid #0047ff;
  color: white;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0px 0px;
  padding: 12px 8px;
  width: 120px;
  text-align: center;
  cursor: pointer;
}

.on-off-btn button:first-child {
  outline: none;
  background-color: #ffffff;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.on-off-btn button:last-child {
  outline: none;
  background-color: #888888;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.search-box {
  margin-top: 6px;
  width: 70%;
  border-radius: 12px;
  outline: none;
  border: 2px solid blue;
  height: 70%;
}
.search-box:focus {
  border: 2px solid blue;
  text-align: end;
}

/* //new */
.h1Text {
  letter-spacing: 9px;
}
.myCardDesign {
  border: 2px solid #1a456d98 !important;

  background-color: #000a30 !important;
  padding: 3px !important;
  border-radius: 1rem !important;
  /* width: 300px; */
  min-height: 315px;
  max-height: 315px;
  overflow: hidden;
}
.myCardDesign:hover {
  border: 2px solid blue !important;
}
.myColDiv {
  flex: 0 0 19.666667%;
  min-width: 19%;
}
.filterDiv select {
  background-color: white;
  color: #0047ff !important;
}
.filterDiv .card {
  background-color: #0b3f70;
  /* background-color:  red !important ; */
}

.filterDiv input::placeholder {
  background-color: white;
  color: #0047ff !important;
}
.nftRouteBtn {
  background-color: #1a456d;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 8px 20px;
  display: block !important;
}
.nftRouteBtn:hover {
  background-color: #1a456d98;
}

.myHr {
  border: 1px solid #9ca0d2;
}
.mytime{
  background-color: #0b3f70;
  font-size: 13px;
  padding: 4px 12px;
  border-radius: 8px;
  width: 90px !important;
}

/* media queries  */
@media only screen and (max-width: 1023px) {
  /* For tablets: */
  .myColDiv {
    flex: 0 0 19%;
    min-width: 30%;
  }
}

@media only screen and (max-width: 767px) {
  /* For tablets: */
  .myColDiv {
    flex: 0 0 19%;
    min-width: 50%;
  }
}
@media only screen and (max-width: 425px) {
  /* For tablets: */
  .myColDiv {
    flex: 0 0 19%;
    min-width: 100%;
  }
}
