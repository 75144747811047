.nftdetailsBtn {
  border-radius: 10px;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 10px 15px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid #0047ff;
  color: white;
  width: 120px;
}
.active {
  background-color: #0047ff;
}
.nftdetailsBtn:hover {
  background-color: #0047ff;
  color: #fff;
}
.nftdetailsBtn:focus {
  outline: none;
}
.myNftDetailsImg {
  border: 1px solid #0047ff;
  border-radius: 12px;
  width: 250px;
}
.myNftDetailsImg img {
  object-fit: cover;
  border-radius: 12px;
  padding: 4px;
}

.myNftDetailsRow {
  padding: 16px 0px;
  border: 2px solid #0047ff;
  border-radius: 12px;
}
.myHr {
  border: 1px solid #9ca0d2;
}

.mdBorder {
  border-right: 1px solid white;
}
.clientSocial {
  background-color: #1a456d !important;
  border: none;
  border-radius: 8px;
  outline: none;
  color: white;
  margin: 0px 12px;
}
.clientSocial img {
  filter: invert(100%) sepia(16%) saturate(7463%) hue-rotate(222deg)
    brightness(119%) contrast(115%);
  padding: 5px 0px;
}
.clientSocial img:hover {
  transition:  0.25s;
  transform: scale(1.2);
}

@media only screen and (max-width: 425px) {
  /* For tablets: */
  .mdBorder {
    border-right: none;
  }
  .setTimeBtn {
    margin-top: 3px;
    font-size: 12px !important;
    padding: 15px 10px !important;
  }
}
