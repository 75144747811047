.myClaimBox {
  border: 2px solid #0047ff;
  border-radius: 12px;
  background-color: #000a42 !important;
  box-shadow: rgb(4 97 255 / 25%) 0px 4px 8px -2px,
    rgb(12 101 255 / 8%) 0px 0px 0px 1px !important;
}
.oneBtn {
  width: 100px;
  border-radius: 12px;
  outline: none;
  border: none;
  background-color: #1a456d;
  padding: 8px 20px;
  text-transform: uppercase;
  color: white;
  font-weight: 500;
}

.spinner-border{
    width: 1.5rem !important;
    height: 1.5rem !important;
}